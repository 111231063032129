import "../scss/app.scss"
// esm-bundlers includes the compiler, the compiler is needed for in-DOM templates or templates via inline JavaScript strings
// See: https://v3.vuejs.org/guide/installation.html#explanation-of-different-builds
import {createApp} from 'vue/dist/vue.esm-bundler'
const Root = {
  delimiters: ['[[', ']]'],
}

const app = createApp(Root)
app.config.globalProperties.window = window
app.mount('.page')

